/* Estilos.css o el archivo CSS que estés utilizando */

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Asegúrate de que el contenedor ocupe toda la altura de la pantalla */
    background-color: #f7f7f7; /* Color de fondo general */
}

.login-box {
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.form-group {
    margin-bottom: 1.5rem;
}

input[type="email"], input[type="password"] {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

button {
    width: 100%;
    padding: 0.8rem;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}

.error-message {
    color: red;
    margin-bottom: 1rem;
}
