.map-legend-container {
    position: relative;
    top: 50%;
    right: 82%;
    z-index: 1000; /* Asegura que la leyenda esté sobre el mapa */
  }
  
  .map-legend {
    /* Estilos para la leyenda, similar a los estilos anteriores */
  position: absolute;
  top: 160px; /* Ajusta la distancia desde la parte superior según tu preferencia */
  right: 10px; /* Ajusta la distancia desde la izquierda según tu preferencia */
  z-index: 1000; /* Asegura que la leyenda esté sobre el mapa */
  background-color: rgba(255, 255, 255, 0.8); /* Ajusta el fondo de la leyenda */
  padding: 8px; /* Ajusta el espaciado interno de la leyenda */
  border-radius: 0px; /* Ajusta la esquina redondeada de la leyenda */
  border: 1px solid #ccc;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    font-size: 12px;
    font-family:cursive;
  }
  
  .legend-color {
    width: 20px;
    height: 10px;
    margin-right: 5px;
    border-radius: 0%;
  }

  .legend-description {
    margin-bottom: 10px;
  }
  