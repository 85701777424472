.legendgosu {
  display: flex;
  flex-direction: column;
  border-radius: 5%;
  background-color: rgb(136 136 136);
  padding: 10%;
  width: 180px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 10px; /* Ajusta el espaciado entre elementos */
}

.control {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000;
    background-color: #ffffff;
    border: 1px solid rgba(0,0,0,0.2);
    padding: 10px;
    box-shadow: 0 1px 5px rgba(0,0,0,0.65);
  }

  .flash-animation {
    animation-name: flash;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  
  @keyframes flash-animation {
    0% {
      border-color: rgb(82, 243, 82);
    }
    50% {
      border-color:rgb(24, 227, 24);
    }
    100% {
      border-color: green;
    }
  }
  
  

  .controlv2 {
    position: absolute;
    top: 6%;
    left: 50%; /* Alinea el control horizontalmente en el centro */
    transform: translate(-50%, -50%); /* Centra el control utilizando transform */
    z-index: 1000;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    border-radius: 5px;
    
  }

  .buscador {

    background-color: #b4b4cf;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    border-radius: 5px;
 
    
  }
  .rounded-input {
    border-radius: 10px; /* Ajusta el valor según tus preferencias */
  }
  
  

  
  .sub-item {
    padding-left: 20px; /* Sangría para los elementos secundarios */
  }
  
  /* Puedes agregar más estilos personalizados según tus preferencias */
  
  
  .h-full.w-64 {
    width: 17rem;
  }
  .user-item {
    display: flex; /* Mostrar elementos como columnas */
    align-items: center; /* Centrar verticalmente */
    margin-bottom: 10px; /* Espacio entre filas */
  }
  
  .user-avatar {
    margin-right: 10px; /* Espacio entre la foto y el nombre */
  }

  .avatar-gozu{
    width: 30px; height: 30px; border-radius: 50%;
  }
  
  .user-details {
    flex: 1; /* Ocupa todo el espacio disponible */
  }
  
  .user-date {
    font-size: 12px;
    color: #555;
  }
  