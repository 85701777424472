.dashboard-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    
}

.data-section {
    margin-top: 20px;
    padding: 20px;
    background-color: #f8f8f8; /* color de fondo ligero */
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* sombra ligera */
    width: 100%;
    max-width: 1000px; /* ajusta según tu preferencia */
}

.display {
    width: 100%;
}


.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

}

.menu-icon {
    background: none;
    border: none;
    font-size: 24px;
    color: green;
    cursor: pointer;
    margin-right: 80%; /* Hace que el espacio restante a la derecha del ícono sea tomado como margen, empujando los elementos a la derecha */
}

.header-end {
    display: flex;
    align-items: center;
}

.header-logo {
    height: 30px; 
    margin-right: 1rem; 
}

.icon-button {
    background: none;
    border: none;
    font-size: 24px;
    color: green;
    cursor: pointer;
    margin-right: 0.5rem;
}

.admin-name {
    color: green;
    font-weight: bold;
}

.dashboard-content {
    margin-top: 60px;
    padding: 15px;
}
